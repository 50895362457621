import React from "react";
import { graphql } from "gatsby";

import Category from "../components/projects/category"
import SEO from "../components/seo";
import Layout from "../components/layout";

import HeroBanner from "../components/page-hero-banner";
import styles from "../scss/layouts/projects_index.module.scss";
import ProjectsCta from "../components/cta/cta_projects";


export default ({ data }) => {
  const pageData = data.markdownRemark;
  const projects = data.allMarkdownRemark;
  const heroImageSources = [
    data.largeHero.childImageSharp.fluid,
    {
      ...data.smallHero.childImageSharp.fluid,
      media: `(max-width: 800px)`,
    },
  ]

  return (
    <Layout>
      <SEO
        title={pageData.frontmatter.title}
        description={pageData.frontmatter.intro }
        pathname={pageData.fields.slug}
      />
      <HeroBanner title={pageData.frontmatter.title}
                  introduction={pageData.frontmatter.intro}
                  imageSources={heroImageSources}/>
      <div className={styles.projects_index}>
        <div className={styles.projects_index__inner}>
          <div className={styles.projects_index__mobile}>
            <p dangerouslySetInnerHTML={{ __html: pageData.frontmatter.intro }} />
          </div>
          <div dangerouslySetInnerHTML={{ __html: pageData.html }} />
        </div>
      </div>

      <div className={styles.projects_index__project_wrapper}>
      { projects.nodes.map(function(project, index) {
        return <Category key={ index }
                       name={ project.frontmatter.title }
                       excerpt={ project.frontmatter.intro }
                       image={ project.frontmatter.featuredImage }
                       url={ project.fields.slug}/>;
      }) }
      </div>
      <ProjectsCta />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        template
        intro
      }
      html
      fields {
        slug
      }
    }
    largeHero:file(relativePath: {eq: "hero_banners/projects_index@2x.jpg"}) {
      id
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    smallHero:file(relativePath: {eq: "hero_banners/projects_index.jpg"}) {
      id
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "/\\/projects\\/[a-z0-9\\\\-]+.md$/"}
      }
      sort: {
        fields: frontmatter___rank,
        order: DESC
      }) {
      nodes {
        fields {
          slug
        }
        html
        frontmatter {
          title
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

