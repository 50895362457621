import React from "react";
import styles from "../../scss/modules/cta_block.module.scss";
import cta from "../../scss/elements/buttons.module.scss";
//import image from "../../images/cta_projects@2x.jpg";
import image from "../../images/get_in_touch.jpg";
import { Link } from "gatsby"

export default () => (
    <div className={styles.cta}>
        <div className={styles.cta__inner}>
            <div className={styles.cta__content}>
                <h2>We work on a range of projects</h2>
                <p>Get in touch with one of our planning or landscaping architect experts about your project today.</p>
                <Link className={cta.arrow_button} to="/contact">Get in touch</Link>
            </div>
            <div className={styles.cta__image}>
                <img src={image} alt={"Get in touch with Northern Planners"}></img>
            </div>
        </div>
    </div>
  )
