import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import styles from "../../scss/modules/project_link.module.scss"
import cta from "../../scss/elements/buttons.module.scss";

export default ( project ) => {

  return (
    <div className={styles.project_link} itemScope itemType="https://schema.org/Project">
        <div className={styles.project_link__image}>
        <Link to={project.url} alt={"View '"+project.name+"' Project"}>
            <Img fluid={ project.image.childImageSharp.fluid } alt={project.name} />
        </Link>
        </div>
        <div className={styles.project_link__content}>
            <h2 itemProp="name"><a href={project.url} alt={"View '"+project.name+"' Project"}>{project.name}</a></h2>
            <p>{project.excerpt}</p>
            <Link className={cta.button} to={project.url} alt={"View '"+project.name+"' Project"}>View our {project.name} project's</Link>
        </div>
    </div>
  )
}
